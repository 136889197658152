import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { mapEdgesToNodes } from "../../lib/helpers";

const ReviewCards = () => {
  const data = useStaticQuery(graphql`
    {
      google: file(relativePath: { eq: "reviews/Google.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 85)
        }
      }
      yelp: file(relativePath: { eq: "reviews/Yelp.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 85)
        }
      }
      facebook: file(relativePath: { eq: "reviews/facebook.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 104)
        }
      }
      defaultProfilePicture: file(relativePath: { eq: "reviews/Group 2.png" }) {
        publicURL
      }
      fivestars: file(relativePath: { eq: "reviews/Stars.svg" }) {
        publicURL
      }
      reviews: allGoogleReviewsSheet(sort: { fields: date, order: DESC }) {
        edges {
          node {
            date
            profilePicture
            firstName
            lastName
            review
            platform
          }
        }
      }
    }
  `);

  const reviewNodes = (data || {}).reviews ? mapEdgesToNodes(data.reviews) : [];

  return (
    <>
      {reviewNodes.map((review, i) => {
        return (
          <div className="md:col-span-6 col-span-12">
            <div
              className="col-span-6  border-gray-500 border-2 mb-6  break-inside-avoid rounded-lg bg-white py-8 px-6 shadow-5xl md:mb-10 md:p-8"
              key={i}
            >
              <div className="mb-4 flex items-center justify-between ">
                <div className="flex items-center space-x-2">
                  {review.profilePicture && review.profilePicture.asset ? (
                    <div className="mb-2 inline-flex justify-center rounded-full border border-white">
                      <GatsbyImage
                        image={review.profilePicture.asset.gatsbyImageData}
                        alt={`${review.firstName} profile headshot`}
                        loading="lazy"
                        width={40}
                        height={40}
                        className="z-0 rounded-full"
                      />
                    </div>
                  ) : (
                    <img
                      src={data.defaultProfilePicture.publicURL}
                      width="40"
                      alt="Default profile headshot"
                    />
                  )}

                  <div>
                    <span className="pl-2 font-bold text-typography-heading">
                      {`${review.firstName} ${review.lastName}`}
                    </span>
                    <img
                      className="pl-2"
                      src={data.fivestars.publicURL}
                      width="90"
                      alt="5 Stars"
                    />
                  </div>
                </div>
              </div>
              <blockquote>
                <q className="mb-6 block font-normal before:hidden">
                  {review.review}
                </q>
              </blockquote>

              {review.platform === "Google" && (
                <GatsbyImage
                  image={data.google.childImageSharp.gatsbyImageData}
                  loading="lazy"
                />
              )}

              {review.platform === "Yelp" && (
                <GatsbyImage
                  image={data.yelp.childImageSharp.gatsbyImageData}
                  loading="lazy"
                />
              )}

              {review.platform === "Facebook" && (
                <GatsbyImage
                  image={data.facebook.childImageSharp.gatsbyImageData}
                  loading="lazy"
                />
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ReviewCards;
