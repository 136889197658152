import { graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import React from "react";

import styled from "@emotion/styled";
import tw from "twin.macro";
import ButtonSolid from "../components/Button/ButtonSolid";
import CTABackgroundImage from "../components/CTA/CTABackgroundImage";
import Layout from "../components/Layout";
import ReviewCards from "../components/Reviews/ReviewCards";
import SearchEngineOptimization from "../components/SEO";

const Page = ({ data }) => {
  const heroImages = [
    data.heroDesktop.childImageSharp.fixed,
    {
      ...data.heroMobile.childImageSharp.fixed,
      media: `(max-width: 767px)`,
    },
  ];
  return (
    <Layout
      headerStyle="standard"
      headerLinkColor="dark"
      headerHasBorder={false}
    >
      <SearchEngineOptimization
        title="Reviews | Painted Rhino | Fiberglass Manufacturing Company"
        description="Read verified Painted Rhino reviews here. We're a fiberglass and composite manufacturing company based in California and serving nationwide. Get a quote!"
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <BackgroundImage fluid={heroImages} className="w-full py-22 md:py-28">
        <div className="container relative">
          <div className="container relative text-white mt-5">
            <header className="text-center ">
              <h2 className="text-white">Satisfied Dreamers</h2>
              <p className="text-2xl">
                We love what we do. Hear from customers who love our work, too.
              </p>
              <ButtonSolid
                className="is-light "
                href="/review-us/"
                text="Leave a Review"
              />
            </header>
          </div>
        </div>
      </BackgroundImage>

      <div className="py-22 grid grid-cols-12 lg:px-32 lg:gap-x-12">
        <ReviewCards className="col-span-auto md:col-span-6" />
      </div>

      <CTABackgroundImage />
    </Layout>
  );
};

export default Page;

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Homepage_FB.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Homepage_Twitter.jpg" }
    ) {
      publicURL
    }
    heroDesktop: file(relativePath: { eq: "reviews/hero.png" }) {
      childImageSharp {
        fixed(width: 2880, height: 1432) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    heroMobile: file(relativePath: { eq: "reviews/reviews-hero-mobile.jpg" }) {
      childImageSharp {
        fixed(width: 2880, height: 1432) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;
